import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: 100 0;
  }
`;

const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Circle = styled.svg`
  width: 100px;
  height: 100px;
  transform: rotate(-90deg); 
`;

const CirclePath = styled.circle`
  fill: none;
  stroke: #18E2D3; 
  stroke-width: 8;
  stroke-dasharray: 0 100;
  animation: ${rotate} 10s linear forwards; /* Animated circle */
`;

const TimerText = styled.span`
  position: absolute;
  font-size: 1.5rem;
  font-weight: bold;
  color: #18E2D3;
`;

export function CircularCountdown({ setQuestionError, func, reFetch, setReFetch} ) {
  const [count, setCount] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => {
        if (prev === 1) {
          prev = 10;
        }
        return prev - 1;
      });
    }, 1000);

    const timeout = setTimeout(() => {
      if(reFetch<3){
        setReFetch((prev)=>prev+1);
        setQuestionError("");
      }else{
        func();
        setQuestionError("");
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [func]);

  return (
    <CountdownWrapper>
      <Circle>
        <CirclePath
          cx="50"
          cy="50"
          r="45"
          strokeDasharray="283"
          strokeDashoffset="283"
        />
      </Circle>
      <TimerText>{count}</TimerText>
    </CountdownWrapper>
  );
}
