import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ContactFilter,
  EmailFilter,
  NameFilter,
  YOEFilter,
} from "./CustomFilter";
import { CandidatePoolGrid } from "./CandidatePoolGrid";
import ActionRenderer from "./ActionHandler";
import { dateConversion } from "../../utils/timeZoneConversation";
import info from "../../assets/info.png";
import info2 from "../../assets/info-dark.png";
import styled from "styled-components";

export const PoolData = ({
  candidateTrigger,
  setCandidateTrigger,
  filteredData,
  candidates,
  search,
  setFilteredData,
  setCandidates,
  setSearch,
  setTotal,
  allCandidatesData,
}) => {
  const [tableData, setTableData] = useState([]);

  const commonFilterParams = {
    setFilteredData,
    setCandidates,
    setSearch,
    setTotal,
    allCandidatesData,
  };

  const [colDefs] = useState([
    {
      field: "Name",
      filter: NameFilter,
      filterParams: commonFilterParams,
      flex: 1.5,
    },
    {
      field: "Email",
      filter: EmailFilter,
      filterParams: commonFilterParams,
      flex: 2,
    },
    {
      field: "Contact",
      flex: 1,
      filter: ContactFilter,
      filterParams: commonFilterParams,
    },
    { field: "Date of Reg", flex: 1 },
    {
      field: "YOE",
      filter: YOEFilter,
      filterParams: commonFilterParams,
      flex: 1,
    },
    { field: "Current Location", flex: 1 },
    {
      field: "tags",
      flex: 1.5,
      filter: false,
      cellRenderer: TagsCellRenderer,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: "Action",
      flex: 1,
      cellRenderer: ActionRenderer,
    },
  ]);

  // Transform row data function
  const transformData = (data) => {
    return data?.map((row, index) => ({
      key: index,
      Name: row?.firstName ? row?.firstName : "...",
      Email: row?.email ? row?.email : "...",
      Contact: row?.contact ? row?.contact : "...",
      "Date of Reg": row?.createdAt ? dateConversion(row?.createdAt) : "...",
      YOE: row?.yoe ? Math.floor(parseInt(row.yoe)) || "N/A" : "N/A",
      "Current Location": row?.currentLocation,
      // Tags: row?.tags?.length !== 0 ? row?.tags?.split(",") : [],
      extraInfo: { row, index, candidateTrigger, setCandidateTrigger },
    }));
  };

  let dataToDisplay = candidates;
  useEffect(() => {
    dataToDisplay = search ? filteredData : candidates;
    const transformedData = transformData(dataToDisplay);
    setTableData(transformedData);
  }, [search, filteredData, candidates, candidateTrigger]);

  const onGridReady = useCallback((params) => {
    setTableData(candidates);
  }, []);

  return (
    <>
      <CandidatePoolGrid
        tableData={tableData}
        colDefs={colDefs}
        onGridReady={onGridReady}
      />
    </>
  );
};

export const TagsCellRenderer = (props) => {
  const { row } = props?.data?.extraInfo || {};
  const tags = row?.tags;

  const tagRef = useRef(null);
  const tagsArray = tags?.split(",");

  const [isClicked, setIsClicked] = useState(false);

  if (tagRef.current) {
    const cellWidth = tagRef.current.offsetWidth;
    const textWidth = tagRef.current.scrollWidth;
    console.log(cellWidth, "and text width : ", textWidth);
  }

  return (
    <TagsDiv className="tags-container" ref={tagRef}>
      
      {tagsArray?.length > 0 && (
        <img
          src={!isClicked ? info : info2}
          alt="info"
          onClick={() => setIsClicked(!isClicked)}
          className="info-icon"
        />
      )}
      {isClicked ? (
        tagsArray?.map((tag, index) => {
          if (index >= 10) {
            return null;
          }
          return (
            <span key={index} className="tag-item">
              {tag}
            </span>
          );
        })
      ) : tagsArray?.length > 0 ? (
        <span className="tag-item">
          {tagsArray[0]?.length > 15
            ? `${tagsArray[0].slice(0, 15)}...`
            : tagsArray[0]}
        </span>
      ) : (
        <span>N/A</span>
      )}
    </TagsDiv>
  );
};

const TagsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  transition: all 0.3s ease;

  .tag-item {
    // white-space: nowrap;
    cursor: default;
    line-height: 25px;
    background-color:#18E2D3;
    border:1px solid white;
    border-radius:5px;
    color:white;
    margin:1.5px;
    padding:0px 2px;
  }
  img {
    position: absolute;
    top: 10px;
    right: 0px;
    height: 15px;
    width: 15px;
  }
`;
