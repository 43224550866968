import React from "react";
import styled from "styled-components";

const InterviewTerms = () => {
  return (
    <Box>
      <span className="text">
        Welcome to the Otohire online AI interview/test! This is your chance to
        showcase your skills and knowledge across various domains. Before diving
        in, take a moment to familiarize yourself with the guidelines below.
      </span>
      <span className="title">Basic Requirements:</span>
      <span className="text">
        To attempt this Interview, ensure you should have:
      </span>
      <ol className="orderedList">
        <li className="text">Stable internet connection and a computer.</li>
        <li className="text">Webcam and a microphone.</li>
      </ol>

      <span className="title">Instructions Overview:</span>
      <span className="text">
        Prior to commencing the Interview, carefully peruse and comprehend the
        provided instructions. Manage your time wisely, focus on questions
        you're confident about first, and minimize distractions.
      </span>

      <span className="title">FullScreen & Tab Change Guidelines:</span>
      <ol>
        <li className="text">
          Maintain full-screen mode throughout the interview. Exceeding once
          exits from full-screen mode will result in automatic termination and
          submission.
        </li>
        <li className="text">
          Limit tab changes to ensure interview integrity. More than one tab
          changes will result in automatic termination and submission.
        </li>
      </ol>

      <span className="title">During the Test:</span>
      <ol>
        <li className="text">
          Concentrate: Avoid distractions, focus solely on the questions.
        </li>
        <li className="text">
          Independence: Complete the test on your own, maintaining academic
          integrity.
        </li>
        <li className="text">
          Review: Double-check your answers before submission.
        </li>
        <li className="text">
          Timely Submission: Submit within the allocated time frame
        </li>
      </ol>
      <span className="title">Communication Guidelines:</span>
      <span className="text">
        Articulate clearly, omitting filler words. Project your voice, enunciate
        properly during the video interview.
      </span>

      <span className="title">Scoring:</span>
      <span className="text">
        Your score will derive by Otohire AI/ML Systems from multiple-choice
        questions, coding challenges, subjective or video interview.
      </span>

      <span className="title">Test Duration:</span>
      <span className="text">
        Expect the test to last around 60 minutes, featuring multiple-choice
        questions, coding challenges, subjective or video interview.
      </span>

      <span className="title">Preparation:</span>
      <span className="text">
        Find a quiet, comfortable space. Ensure a fully charged computer and a
        stable internet connection. Close other programs before starting.
      </span>

      <span className="title">Initiating the Interview:</span>
      <span className="text">
        Click on "Start Interview" after you have reviewed and agreed to the
        consent. <br /> Additionally, please grant permission for both screen
        sharing and webcam access before starting the interview.
      </span>

      <span className="title">Additional Instructions:</span>
      <span className="text">
        No breaks during the interview. Refrain from using any reference
        materials.
      </span>

      <span className="title">Terms of Service:</span>
      <span className="text">By participating, you agree to:</span>
      <ol>
        <li className="text">Provide truthful information.</li>
        <li className="text">Avoid cheating.</li>
        <li className="text">Abstain from unauthorized software.</li>
        <li className="text">Keep test results confidential.</li>
      </ol>

      <span className="text">
        <b>
          Initiate the test by reviewing and agreeing to the consent/terms. Good
          luck!
        </b>
      </span>
    </Box>
  );
};

export default InterviewTerms;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 60vh;
  width: 90%;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  border: 0.075rem solid grey;
  border-radius: 1rem;
  overflow: auto;
  padding: 1rem;
  box-sizing: border-box;

  .title {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0.5rem 0 -0.25rem 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin: 0rem 0;
  }

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background: grey;
    width: 0.2rem;
    border-radius: 0.2rem;
  }

  & {
    scrollbar-width: none;
  }
`;
