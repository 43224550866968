import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reFetchInterview, updateInterview } from "../slices/interviewSlice";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { CircularCountdown } from "../components/commonComponents/CountDownLoader";
import CommonButton from "../components/Interviews/SeekerDashboard/seekerCommonComponents/CommonButton";
import { updateStatus } from "../functions/api/interview/updateStatus";
const style = {
  backgroundColor: "var(--backgroundColor)",
  color: "var(--cyan)",
  position: "fixed",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1.4rem",
  justifyContent: "center",
  alignItems: "center",
  left: 0,
  top: 0,
};
const ErrorHandle = styled.div`
  background-color: #fff4f4;
  color: #d32f2f;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #f8d7da;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  max-width: 500px;
  margin: 2rem auto;
  position: relative;
  h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    gap: 0.8rem;
  }
`;

function CreateInterview() {
  const { interviewState } = useParams();
  const stringsArray =
    localStorage.getItem("currentInterview") == "profile"
      ? [
          "Analyzing JD",
          "Analyzing Resume",
          "Creating Questions",
          "Creating Interview",
          "Preparing Interview",
        ]
      : [
          "Analyzing skills",
          "Creating Questions",
          "Creating Interview",
          "Preparing Interview",
        ];

  const [currentString, setCurrentString] = useState(stringsArray[0]);
  const { interviewId } = useParams();
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);

  const interviewData = useSelector((state) => state?.interview?.interviewData);
  const interviewErrorData = useSelector((state) => state?.interview?.error);
  const [QuestionError, setQuestionError] = useState("");
  const [reFetch, setReFetch] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let currentIndex = 1;
    const intervalId = setInterval(() => {
      setCurrentString(stringsArray[currentIndex]);
      currentIndex++;

      if (currentIndex === stringsArray.length) {
        clearInterval(intervalId);
        setCurrentString(stringsArray[stringsArray.length - 1]);
      }
    }, 10000);
    if (reFetch > 0 && reFetch <= 3) {
      dispatch(reFetchInterview({ id: interviewId, accessToken }))
        .unwrap()
        .catch((error) => {
          setQuestionError(error);
        });
    } else {
      dispatch(
        updateInterview({ id: interviewId, status: "started", accessToken })
      )
        .unwrap()
        .catch((error) => {
          setQuestionError(error);
        });
    }
    return () => clearInterval(intervalId);
  }, [reFetch]);

  useEffect(() => {
    if (
      interviewData?.status == "STARTED" &&
      interviewData?.id == interviewId
    ) {
      navigate(`/ongoing-interview/${interviewId}/${interviewState}`);
    } else if (interviewErrorData) {
      toast.warn(interviewErrorData);
      console.log("create interview : ", interviewErrorData);
      navigate("/dashboard/jobseeker");
    }
  }, [interviewData]);

  const autoSubmit = async () => {
    try {
      const terminate = await updateStatus(
        interviewId,
        "CANCELED",
        accessToken,
        "Question formation failed"
      );
      if (terminate) {
        navigate("/dashboard/jobseeker");
      }
    } catch (error) {
      toast.error(
        error?.message || "error occurring while terminating interview"
      );
    }
  };
  return (
    <>
      {QuestionError ? (
        <ErrorHandle>
          <h3>{QuestionError || "An error occurred"}</h3>
          <h3>
            {reFetch < 3
              ? "Question fetching ..."
              : "Technical issue occurring, please connect with HR."}
          </h3>
          <div className="loader-container">
            <CircularCountdown
              setQuestionError={setQuestionError}
              func={autoSubmit}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
            {reFetch === 3 && (
              <CommonButton text="Bact To Dashboard" func={autoSubmit} />
            )}
          </div>
        </ErrorHandle>
      ) : (
        <Box sx={style}>
          <CircularProgress color="inherit" />
          <span style={{ color: "var(--color)" }}>{currentString}</span>
        </Box>
      )}
    </>
  );
}

export default CreateInterview;
