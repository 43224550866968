import React, { useEffect,  useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import {useParams } from "react-router";
import OngoingInterview from "./OngoingInterview";
import Alerts from "../components/Interviews/ProctoringComponents/Alerts";
import { useProctoring } from "../components/Interviews/ProctoringHooks/useProctoring";
import { useSelector } from "react-redux";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

const Proctored = () => {
  const { interviewState, interviewId } = useParams();
  const [examHasStarted, setExamHasStarted] = useState(false);
  const [tabFocusCount, setTabFocusCount] = useState(0); 
  const [escapeFullScreenCount, setEscapeFullScreenCount] = useState(0); 
  const { fullScreen, tabFocus } = useProctoring({
    forceFullScreen: true,
    preventTabSwitch: true,
    preventContextMenu: true,
    preventUserSelection: true,
    preventCopyPaste: true,
  });
  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  useEffect(() => {
    function handleKeyDown(event) {
      if (
        (event.ctrlKey && event.shiftKey && event.key === "I") ||
        (event.ctrlKey && event.shiftKey && event.key === "J") ||
        (event.ctrlKey && event.shiftKey && event.key === "C") ||
        (event.ctrlKey && event.key === "Tab") ||
        (event.ctrlKey && event.key === "r") ||
        (event.ctrlKey && event.key === "F5") ||
        (event.ctrlKey && event.key === "w") ||
        (event.ctrlKey && event.key === "t") ||
        (event.ctrlKey && event.key === "n") ||
        (event.ctrlKey && event.key === "U") ||
        (event.ctrlKey && event.key === "Shift" && event.key === "t") ||
        (event.altKey && event.key === "Tab") ||
        (event.metaKey && event.key === "Tab") ||
        (event.ctrlKey && event.key === "F12") ||
        (event.metaKey && event.key === "Option" && event.key === "I") ||
        (event.metaKey && event.altKey && event.key === "I") ||
        (event.metaKey && event.altKey && event.key === "J")
      ) {
        event.preventDefault();
      }
    }

    if (examHasStarted) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [examHasStarted]);

  const handleProctorEvents = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    console.log("state tab", tabFocusCount);
    const prams = {
      numVal: tabFocusCount + 1,
      type: "unfocus",
    };
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/interviews/${interviewId}/proctor-events`,
      prams,
      config
    );
  };

  useEffect(() => {
    if (examHasStarted && tabFocus.status === false ) {
      setTabFocusCount((prevCount) => prevCount + 1);
      try {
        handleProctorEvents();
      } catch (error) {
        toast.error(error?.message || "error occurring while switching tabs");
      }
    }
  }, [examHasStarted, tabFocus.status]);

  useEffect(() => {
    if (examHasStarted && fullScreen.status === "off") {
      setEscapeFullScreenCount((prevCount) => prevCount + 1);
    }
    try {
      handleProctorEvents();
    } catch (error) {
      toast.error(error?.message || "error occurring while switching tabs");
    }
  }, [examHasStarted, fullScreen.status]);

  const handleStartExam = () => {
    if (!areDevToolsClosed()) {
      alert("Please close the Developer Tools before starting the exam.");
    } else {
      fullScreen.trigger();
      setTimeout(() => {
        setExamHasStarted(true);
      }, 100);
    }
  };

  const areDevToolsClosed = () => {
    return true;
  };

  return (
    <Container>
      <OngoingInterview
        start={examHasStarted}
        handleStart={handleStartExam}
        interviewState={interviewState}
        escapeFullScreenCount={escapeFullScreenCount}
        tabFocusCount={tabFocusCount}
      />
      {examHasStarted && <Alerts fullScreen={fullScreen} tabFocus={tabFocus} />}
    </Container>
  );
};

export default Proctored;
