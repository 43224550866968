import axios from "axios";

export const reFetchInterviews = async (id, accessToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  };

  //TODO
  try {
    const response = await axios.patch(
      // `${process.env.REACT_APP_API_URL}/api/interviews/reFetch/${id}`,{}, config
      `http://localhost:7000/api/interviews/reFetch/${id}`,
      {},
      config
    );
    console.log("Data:", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
