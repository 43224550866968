import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createInterview } from "../functions/api/interview/createInterview";
import { updateStatus } from "../functions/api/interview/updateStatus";
import { getQuestion } from "../functions/api/interview/getQuestion";
import { submitAnswer } from "../functions/api/interview/submitAnswer";
import { submitAnswerWithFile } from "../functions/api/interview/submitAnswerWithFile";
import jdSlice from "./jdSlice";
import { reFetchInterviews } from "../functions/api/interview/reFetch";

export const createInterviewId = createAsyncThunk(
  "interview/create",
  async ({ jobSummary, resumeText, accessToken }) => {
    const response = await createInterview(jobSummary, resumeText, accessToken);
    return response.data;
  }
);

export const updateInterview = createAsyncThunk(
  "interview/status",
  async ({ id, status, accessToken }, { rejectWithValue }) => {
    let response;
    try {
      response = await updateStatus(id, status, accessToken);
      if (response.response?.data?.status == "FAILED") {
        return rejectWithValue(response?.response?.data);
      }
      return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.notify?.message || error?.message;
      return rejectWithValue(errorMessage);
    }
  }
);
export const reFetchInterview = createAsyncThunk(
  "interview/reFetch",
  async ({ id, accessToken }, { rejectWithValue }) => {
    let response;
    try {
      response = await reFetchInterviews(id, accessToken);
      if (response.response?.data?.status == "FAILED") {
        return rejectWithValue(response?.response?.data);
      }
      return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.notify?.message || error?.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchQuestion = createAsyncThunk(
  "interview/question",
  async ({ jobSummary, resumeText, accessToken }) => {
    const response = await getQuestion(jobSummary, resumeText, accessToken);
    return response.data;
  }
);

export const submit = createAsyncThunk(
  "interview/answer",
  async ({ input, id, lastQuestion, interviewId, accessToken }) => {
    const response = await submitAnswer(
      input,
      id,
      lastQuestion,
      interviewId,
      accessToken
    );
    return response.data;
  }
);

export const submitAnswerFile = createAsyncThunk(
  "interview/fileAnswer",
  async ({ formData, id, lastQuestion, interviewId, accessToken }) => {
    const response = await submitAnswerWithFile(
      formData,
      id,
      lastQuestion,
      interviewId,
      accessToken
    );
    return response.data;
  }
);

const initialState = {
  interviewData: null,
  questionData: null,
  answerData: null,
  status: "idle",
  error: null,
};

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
      state.interviewData = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createInterviewId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createInterviewId.fulfilled, (state, action) => {
        state.interviewData = action.payload;
        state.status = "succeeded";
      })
      .addCase(createInterviewId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateInterview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateInterview.fulfilled, (state, action) => {
        state.interviewData = action.payload;
        state.status = "succeeded";
      })
      .addCase(updateInterview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      })
      .addCase(reFetchInterview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(reFetchInterview.fulfilled, (state, action) => {
        state.interviewData = action.payload;
        state.status = "succeeded";
      })
      .addCase(reFetchInterview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.error?.message;
      })
      .addCase(fetchQuestion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQuestion.fulfilled, (state, action) => {
        state.questionData = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(submit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submit.fulfilled, (state, action) => {
        state.answerData = action.payload;
        state.status = "succeeded";
      })
      .addCase(submit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(submitAnswerFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitAnswerFile.fulfilled, (state, action) => {
        state.answerData = action.payload;
        state.status = "succeeded";
      })
      .addCase(submitAnswerFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { clearError } = interviewSlice.actions;
export default interviewSlice.reducer;
